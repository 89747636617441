import React from "react"
import { Link } from "gatsby"
import { Flex, Text } from "theme-ui"

export default function LinkCard({ image, label, title, body, price, buttonLabel, url, download }) {
  return (
    <>
      {download ?
        <a href={download} download>
          <Flex sx={{ height: "100%", flexDirection: "column", p: 3, mx: -3, "&:hover": { bg: "grayXLight" } }}>
            {image && image}
            <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
              {label && <Text as="p" variant="label">{label}</Text>} 
              {title && <Text as="h2" variant="title02" sx={{ fontFamily: "display", textTransform: "none" }}>{title}</Text>}
              {body && <Text as="p" variant="body" sx={{ m: 0 }}>{body}</Text>}
            </Flex>
            <Flex sx={{ mt: 4, pb: 2, justifyContent: "space-between", borderBottom: "1px solid", }}>
              <Text variant="button">{buttonLabel ? buttonLabel : "Read more"}</Text>
              <Text variant="button">↓</Text>
            </Flex>
          </Flex>
        </a>
      : url ?
          <Link to={url} target={url && url.includes("adventure.game") ? "_self" : "_blank"}>
            <Flex
              sx={{ 
                height: "100%", 
                flexDirection: "column", 
                p: 3, 
                mx: -3, 
                "&:hover": { 
                  bg: "grayXLight" 
                } 
              }}
            >
              {image && image}
              <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
                {label && <Text as="p" variant="label">{label}</Text>} 
                {title && <Text as="h2" variant="title02" sx={{ fontFamily: "display", textTransform: "none" }}>{title}</Text>}
                {body && <Text as="p" variant="body" sx={{ m: 0 }}>{body}</Text>}
              </Flex>
              <Flex sx={{ mt: 4, pb: 2, justifyContent: "space-between", borderBottom: "1px solid", }}>
                <Text variant="button">{buttonLabel ? buttonLabel : "Read more"}</Text>
                {url && <Text variant="button">⟶</Text>}
              </Flex>
            </Flex>
          </Link>
      :
        <Flex sx={{ height: "100%", flexDirection: "column", p: 3, mx: -3 }}>
          {image && image}
          <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
            {label && <Text as="p" variant="label">{label}</Text>} 
            {title && <Text as="h2" variant="title02" sx={{ fontFamily: "display", textTransform: "none" }}>{title}</Text>}
            {body && <Text as="p" variant="body" sx={{ m: 0 }}>{body}</Text>}
          </Flex>
          <Flex sx={{ mt: 4, pb: 2, justifyContent: "space-between", borderBottom: "1px solid", }}>
            <Text variant="button">{buttonLabel ? buttonLabel : "Read more"}</Text>
          </Flex>
        </Flex>
      }
    </>
  )
}
