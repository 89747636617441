import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Grid, Box, Text } from "theme-ui"

const StorePolicies = () => {
  const data = useStaticQuery (
    graphql`
    query {
      contentfulStorePolicies(title: {eq: "Store Policies"}) {
        body {
          json
        }
      }
    }
    `
  )
  
  return (
    <Box sx={{ bg: "grayXLight", mt: [7, 8], mb: [-7, -8] }}>
      <Grid variant="textWrap" sx={{ py: [5, 6] }}>
        <Box>
          <Text as="h3" variant="title03">Store policies</Text>

          <Box>
            {documentToReactComponents(data.contentfulStorePolicies.body.json, {
              renderMark: {
                [MARKS.BOLD]: text => <Text as="span" variant="button">{text}</Text>,
              },
              renderNode: {
                [BLOCKS.HEADING_1]: (_node, children) => <Text as="h1" variant="title01">{children}</Text>,
                [BLOCKS.HEADING_2]: (_node, children) => <Text as="h2" variant="title02" sx={{ mt: 4}}>{children}</Text>,
                [BLOCKS.HEADING_3]: (_node, children) => <Text as="h3" variant="title03" sx={{ mt: 4}}>{children}</Text>,
                [BLOCKS.HEADING_4]: (_node, children) => <Text as="h4" variant="label" sx={{ mt: 3}}>{children}</Text>,
                [BLOCKS.PARAGRAPH]: (_node, children) => <Text as="p" variant="body" sx={{ mb: 2, fontSize: "xs" }}>{children}</Text>,
                [INLINES.HYPERLINK]: (node, children) => {
                  return (
                    <Text as="a" variant="link" href={node.data.uri} target={node.data.uri.includes("adventure.game") ? "_self" : "_blank"} rel={node.data.uri.includes("adventure.game") ? "noreferrer" : null}>{children}</Text>
                    )
                  },
                },
              })}
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}

export default StorePolicies