import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Grid, Box, Text } from "theme-ui"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import LinkCard from "../../components/LinkCard"
import StorePolicies from "../../components/store/StorePolicies"

const StorePage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulProduct(sort: {order: ASC, fields: order}) {
          edges {
            node {
              name
              price
              excerpt
              slug
              status
              category
              images {
                file {
                  url
                }
              }
              order
            }
          }
        }
      }
    `
  )

  return (
    <Layout noButton>
      <SEO title="Store" />

      <Breadcrumbs title="Store" headline="Legendary treasures" />

      <Grid variant="textWrap" sx={{ mb: [5, 6] }}>
        <Text as="p" variant="bodyLarge">Here’s where the magic begins.</Text>
      </Grid>

      <Grid variant="base" columns={[1, 2, 3]} sx={{ gridGap: 6 }}>
        {data.allContentfulProduct.edges.map(edge => {
          return (
            <>
              {(edge.node.category === "Digital Editions") &&
                <LinkCard
                  title={edge.node.name}
                  status={edge.node.status}
                  price={edge.node.price}
                  body={edge.node.excerpt}
                  url={`/store/${edge.node.slug}/`}
                  image={
                    <Box
                      sx={{ 
                        backgroundImage: `url(${edge.node.images[0].file.url})`, 
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: [280, 400, 500],
                        border: "2px solid",
                        mb: 4
                      }} 
                    />
                  }
                  buttonLabel={edge.node.status === "Available" ? `$${edge.node.price}` : edge.node.status}
                />
              }
            </>
          )
        })}
      </Grid>
      
      <StorePolicies />
      
    </Layout>
  )
}

export default StorePage
